export function sortByHasLinks(products) {
  console.log(products);
  products.sort((a, b) => {
    if (a.links.length < b.links.length) {
      return 1;
    }
    if (a.links.length > b.links.length) {
      return -1;
    }
    return 0;
  });
  console.log(products);
  return products;
}
