import {
  tees_004,
  sweats_004,
  jackets_004,
  jewellery_004,
  otherShit_004,
} from "./004";

import {
  tees_005,
  sweats_005,
  hoodies_005,
  jackets_005,
  shirts_005,
  pants_005,
  hats_005,
  otherShit_005,
} from "./005";

import {
  hoodies_006,
  tees_006,
  jackets_006,
  shirts_006,
  pants_006,
  hats_006,
  otherShit_006,
  sweats_006,
} from "./006";

import { jackets_007, pants_007, tees_007 } from "./007";

import { sortByHasLinks } from "../../utils/sortByHasLinks";

const hfd004 = [
  ...tees_004,
  ...sweats_004,
  ...jackets_004,
  ...jewellery_004,
  ...otherShit_004,
];

const hfd005Unsorted = [
  ...hats_005,
  ...tees_005,
  ...sweats_005,
  ...hoodies_005,
  ...jackets_005,
  ...shirts_005,
  ...pants_005,
  ...otherShit_005,
];

const hfd006 = [
  ...hoodies_006,
  ...tees_006,
  ...jackets_006,
  ...shirts_006,
  ...pants_006,
  ...hats_006,
  ...otherShit_006,
  ...sweats_006,
];

const hfd007 = [...tees_007, ...jackets_007, ...pants_007];

const hfd005Latest = hfd005Unsorted.filter((item) => item.latest);
const hfd005NotLatest = hfd005Unsorted.filter(
  (item) => !item.latest && item.links.length > 0
);
const hfd005ComingSoon = hfd005Unsorted.filter(
  (item) => item.links.length <= 0
);

const hfd005 = [...hfd005Latest, ...hfd005NotLatest, ...hfd005ComingSoon];
// const hfd005 = sortByHasLinks(hfd005Unsorted);

const tees = [
  ...tees_007,
  // ...tees_006,
  ...tees_005,
  ...tees_004,
];
const sweats = [
  // ...sweats_006,
  ...sweats_005,
  ...sweats_004,
];
const hoodies = [
  // ...hoodies_006,
  ...hoodies_005,
];
const jackets = [
  ...jackets_007,
  // ...jackets_006,
  ...jackets_005,
  ...jackets_004,
];
const shirts = [
  // ...shirts_006,
  ...shirts_005,
];
const pants = [
  ...pants_007,
  // ...pants_006,
  ...pants_005,
];
const hats = [
  // ...hats_006,
  ...hats_005,
];
const jewellery = jewellery_004;
const otherShit = [
  // ...otherShit_006,
  ...otherShit_005,
  ...otherShit_004,
];

export {
  tees,
  sweats,
  hoodies,
  shirts,
  pants,
  jackets,
  hats,
  jewellery,
  otherShit,
  hfd004,
  hfd005,
  hfd006,
  hfd007,
};
