function logo({ fill, className }) {
  return (
    <svg
      height="472px"
      viewBox="0 0 1000 472"
      version="1.1"
      className={className}
    >
      <title>HFD</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Home---Desktop-HD"
          transform="translate(-53.000000, -101.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g
            id="HFD"
            transform="translate(553.000000, 336.873288) rotate(-360.000000) translate(-553.000000, -336.873288) translate(53.000000, 101.000000)"
          >
            <polygon
              id="Path"
              points="119.042736 1.28424658 119.042736 183.322591 212.29288 183.322591 212.29288 1.28424658 331.335616 1.28424658 331.335616 470.890411 212.29288 470.890411 212.29288 278.299119 119.042736 278.299119 119.042736 470.890411 9.73346213e-14 470.890411 9.73346213e-14 1.28424658"
            ></polygon>
            <polygon
              id="Path"
              points="634.417808 2.14041096 634.417808 93.1595833 468.938596 93.1595833 468.938596 188.136111 586.568397 188.136111 586.568397 279.155283 468.938596 279.155283 468.938596 471.746575 349.315068 471.746575 349.315068 2.14041096"
            ></polygon>
            <path
              d="M787.671233,0 C820.847603,0 850.472149,4.92729535 876.583904,14.8594812 C902.695659,24.7528695 924.904311,39.2631724 943.248892,58.3903897 C961.593473,77.5176071 975.644641,101.261739 985.402397,129.622785 C995.121122,157.983832 1000,190.612614 1000,227.547931 C1000,306.268264 982.436039,366.288153 947.269087,407.607598 C912.102135,448.927043 863.547542,469.606164 801.605308,469.606164 L654.965753,469.606164 L654.965753,0 L787.671233,0 Z M774.400685,386.501703 L786.344178,386.501703 C809.762792,386.501703 828.146404,379.013766 841.416952,364.076689 C847.154512,357.481097 851.916297,350.109553 855.702307,342.000854 C859.449285,333.853358 862.454674,324.309148 864.640411,313.290629 C866.865179,302.310908 868.504482,289.546497 869.636382,275.036195 C870.729251,260.525892 871.275685,243.377352 871.275685,223.590575 C871.275685,175.675538 864.21107,140.253328 850.042808,117.401541 C835.874547,94.549754 813.782988,83.1044617 783.690068,83.1044617 L774.400685,83.1044617 L774.400685,386.501703 L774.400685,386.501703 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default logo;
