import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./SignUp.scss";

const listUrl =
  "https://untilthedawn.us14.list-manage.com/subscribe/post?u=71128ad0f728df8e811fb39ee&amp;id=d11e9aa45c";

function SignUp() {
  return (
    <div className="SignUp">
      <h3>Fucking Sign Up</h3>
      <p>For news on Honey Fucking Dijon Collections &amp; Other Shit</p>
      <MailchimpSubscribe url={listUrl} />
    </div>
  );
}

export default SignUp;
