import React, { useState } from "react";
import "./Page.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Product from "../components/Product/Product";
import {
  tees,
  sweats,
  hoodies,
  shirts,
  pants,
  jackets,
  hats,
  jewellery,
  otherShit,
  hfd004,
  hfd005,
  hfd006,
  hfd007,
} from "../assets/products";
import Logo from "../assets/images/Logo.js";
import MobileNav from "../components/MobileNav/MobileNav";
import SignUp from "../components/SignUp/SignUp";

function App() {
  const [visibleProducts, setVisbleProducts] = useState(hfd007);
  const [showSignup, setShowSignup] = useState(false);
  // const [greenTheme, setGreenTheme] = useState(false);
  // const logoFillLight = greenTheme ? "#ebf5db" : "#FFCFD0";
  // const logoFillDark = greenTheme ? "#6be6a9" : "#fbafa6";
  const logoFillLight = "#fff";
  const logoFillDark = "#eeeeee";

  function navItemClickHandler(productsToShow) {
    setVisbleProducts(productsToShow);
    setShowSignup(false);
  }

  // useEffect(() => {
  //   if (Math.random() < 0.5) {
  //     document.body.classList.add("green");
  //     setGreenTheme(true);
  //   }
  // }, []);

  return (
    <div className="App">
      <Logo className="background-logo" fill={logoFillDark} />
      <Container>
        <MobileNav
          navItemClickHandler={navItemClickHandler}
          logoFill={logoFillLight}
          headingClickHandler={() => navItemClickHandler(hfd007)}
          setShowSignup={setShowSignup}
        />
        <h1 onClick={() => navItemClickHandler(hfd007)}>HONEY FUCKING DIJON</h1>
      </Container>

      <Container>
        <Row>
          <Col lg={3} className="sidebar">
            <ul>
              {tees.length > 0 && (
                <li onClick={() => navItemClickHandler(tees)}>
                  FUCKING T-SHIRTS
                </li>
              )}
              {sweats.length > 0 && (
                <li onClick={() => navItemClickHandler(sweats)}>
                  FUCKING SWEATS
                </li>
              )}
              {hoodies.length > 0 && (
                <li onClick={() => navItemClickHandler(hoodies)}>
                  FUCKING HOODIES
                </li>
              )}
              {shirts.length > 0 && (
                <li onClick={() => navItemClickHandler(shirts)}>
                  FUCKING SHIRTS
                </li>
              )}
              {pants.length > 0 && (
                <li onClick={() => navItemClickHandler(pants)}>
                  FUCKING PANTS
                </li>
              )}
              {jackets.legnth > 0 && (
                <li onClick={() => navItemClickHandler(jackets)}>
                  FUCKING JACKETS
                </li>
              )}
              {hats.length > 0 && (
                <li onClick={() => navItemClickHandler(hats)}>FUCKING HATS</li>
              )}
              {jewellery.length > 0 && (
                <li onClick={() => navItemClickHandler(jewellery)}>
                  FUCKING JEWELLERY
                </li>
              )}
              {otherShit.length > 0 && (
                <li onClick={() => navItemClickHandler(otherShit)}>
                  FUCKING OTHER SHIT
                </li>
              )}
            </ul>
            <ul>
              {hfd007.length > 0 && (
                <li onClick={() => navItemClickHandler(hfd007)}>HFD007</li>
              )}
              {/* {hfd006.length > 0 && (
                <li onClick={() => navItemClickHandler(hfd006)}>HFD006</li>
              )} */}
              {hfd005.length > 0 && (
                <li onClick={() => navItemClickHandler(hfd005)}>HFD005</li>
              )}
              {hfd004.length > 0 && (
                <li onClick={() => navItemClickHandler(hfd004)}>HFD004</li>
              )}
            </ul>
            <ul>
              <li onClick={() => setShowSignup(true)}>FUCKING SIGN UP</li>
            </ul>
            <div>DOVER STREET MARKET</div>
            <div>COMME des GARÇONS</div>
            <div className="copyright">
              &copy; HONEY FUCKING DIJON {new Date().getFullYear()}
            </div>
          </Col>
          <Col>
            <Row>
              {showSignup ? (
                <SignUp />
              ) : (
                visibleProducts.map((product, i) => (
                  <Col lg={4} key={`${product.id}-${i}`}>
                    <Product product={product} />
                  </Col>
                ))
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
