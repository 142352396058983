import React from "react";
import Container from "react-bootstrap/Container";
import Logo from "../../assets/images/Logo.js";
import {
  tees,
  sweats,
  hoodies,
  shirts,
  pants,
  jackets,
  hats,
  jewellery,
  otherShit,
  hfd004,
  hfd005,
  hfd006,
  hfd007,
} from "../../assets/products";

import "./MobileNav.scss";

function MobileNav({
  navItemClickHandler,
  logoFill,
  headingClickHandler,
  setShowSignup,
}) {
  function hamburgerToggle(inputIsChecked) {
    inputIsChecked ? lockBodyScrolling() : unlockBodyScrolling();
  }

  function lockBodyScrolling() {
    document.body.classList.add("no-scroll");
  }

  function unlockBodyScrolling() {
    document.body.classList.remove("no-scroll");
  }

  function onNavItemClick(navTarget) {
    if (navTarget === "signup") {
      setShowSignup(true);
    } else {
      navItemClickHandler(navTarget);
    }
    unlockBodyScrolling();
    document.getElementById("burger").checked = false;
  }

  return (
    <header>
      <input
        id="burger"
        type="checkbox"
        onChange={(e) => hamburgerToggle(e.target.checked)}
      />

      <label htmlFor="burger">
        <span></span>
        <span></span>
        <span></span>
      </label>

      <nav className="mobile-nav">
        <Logo
          className="background-logo nav light-background"
          fill={logoFill}
          onClick={headingClickHandler}
        />
        <Container>
          <h2 onClick={() => onNavItemClick(hfd007)}>HONEY FUCKING DIJON</h2>
          <ul>
            {tees.length > 0 && (
              <li onClick={() => onNavItemClick(tees)}>FUCKING T-SHIRTS</li>
            )}
            {sweats.length > 0 && (
              <li onClick={() => onNavItemClick(sweats)}>FUCKING SWEATS</li>
            )}
            {hoodies.length > 0 && (
              <li onClick={() => onNavItemClick(hoodies)}>FUCKING HOODIES</li>
            )}
            {shirts.length > 0 && (
              <li onClick={() => onNavItemClick(shirts)}>FUCKING SHIRTS</li>
            )}
            {pants.length > 0 && (
              <li onClick={() => onNavItemClick(pants)}>FUCKING PANTS</li>
            )}
            {jackets.legnth > 0 && (
              <li onClick={() => onNavItemClick(jackets)}>FUCKING JACKETS</li>
            )}
            {hats.length > 0 && (
              <li onClick={() => onNavItemClick(hats)}>FUCKING HATS</li>
            )}
            {jewellery.length > 0 && (
              <li onClick={() => onNavItemClick(jewellery)}>
                FUCKING JEWELLERY
              </li>
            )}
            {otherShit.length > 0 && (
              <li onClick={() => onNavItemClick(otherShit)}>
                FUCKING OTHER SHIT
              </li>
            )}
          </ul>
          <ul>
            {hfd007.length > 0 && (
              <li onClick={() => onNavItemClick(hfd007)}>HFD007</li>
            )}
            {/* {hfd006.length > 0 && (
              <li onClick={() => onNavItemClick(hfd006)}>HFD006</li>
            )} */}
            {hfd005.length > 0 && (
              <li onClick={() => onNavItemClick(hfd005)}>HFD005</li>
            )}
            {hfd004.length > 0 && (
              <li onClick={() => onNavItemClick(hfd004)}>HFD004</li>
            )}
          </ul>
          <ul>
            <li onClick={() => onNavItemClick("signup")}>FUCKING SIGN UP</li>
          </ul>
          <div>DOVER STREET MARKET</div>
          <div>COMME des GARÇONS</div>
          <div className="copyright">
            &copy; HONEY FUCKING DIJON {new Date().getFullYear()}
          </div>
        </Container>
      </nav>
    </header>
  );
}

export default MobileNav;
