import React from "react";
import Button from "react-bootstrap/esm/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import "./StoreDropdown.scss";

function StoreDropdown({ links, buyInStore }) {
  const buyText = buyInStore ? "BUY IN STORE" : "BUY";
  return links.length > 0 ? (
    <DropdownButton title={buyText} className="StoreDropdown light-text">
      {links.map((link, i) => {
        const storeText =
          link.store === "end"
            ? "END CLOTHING"
            : `dsm ${link.store.toUpperCase()}`;
        return (
          <Dropdown.Item
            href={link.src}
            key={i}
          >{`VIA ${storeText}`}</Dropdown.Item>
        );
      })}
    </DropdownButton>
  ) : (
    <div className="StoreDropdown light-text">
      <Button className={"cursorDefault"}>COMING SOON</Button>
    </div>
  );
}

export default StoreDropdown;
