export const tees_007 = [
  {
    id: "HFD07T005",
    img: "images/_0026_HFD07T005_WHITE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-1987-cropped-top-white-ss23-hfd07t005",
      },
      {
        store: "singapore",
        src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-1987-cropped-top-white",
      },
    ],
  },
  {
    id: "HFD07T008",
    img: "images/_0028_HFD07T008_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-1987-short-sleeves-t-shirt-black-ss23-hfd07t008",
      },
    ],
  },
  {
    id: "HFD07T307_BLACK",
    img: "images/_0036_HFD07T307_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-basquiat-short-sleeve-t-shirt-black-ss23-hfd07t307",
      },
    ],
  },
  {
    id: "HFD07T307_WHITE",
    img: "images/_0037_HFD07T307_WHITE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-basquiat-short-sleeve-t-shirt-white-ss23-hfd07t307",
      },
    ],
  },
];

export const pants_007 = [
  {
    id: "HFD07P310_BLACK",
    img: "images/HFD07P310_BLACK.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-basquiat-denim-pants-black-ss23-hfd07p310",
      },
      {
        store: "singapore",
        src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-basquiat-denim-pants-black",
      },
    ],
  },
];

export const jackets_007 = [
  {
    id: "HFD07J310_BLACK",
    img: "images/HFD07J310_BLACK.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/products/honey-fucking-dijon-basquiat-denim-jacket-black-ss23-hfd07j310",
      },
      {
        store: "singapore",
        src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-basquiat-denim-jacket-black",
      },
    ],
  },
];
