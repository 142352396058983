export const jackets_006 = [
  {
    id: "HFD06C101",
    img: "/images/HFD__0004_HFD06C101_BLACK_2.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      //   {
      //     store: "paris",
      //     src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //   },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];

export const sweats_006 = [
  {
    id: "HFD06N001_BLUE",
    img: "/images/HFD__0047_HFD06N001_BLUE STRIPES_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      //   {
      //     store: "paris",
      //     src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //   },
    ],
  },
  {
    id: "HFD06N001_RED",
    img: "/images/HFD__0048_HFD06N001_PINK STRIPES_2.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06N101",
    img: "/images/HFD__HFD06N101_BEIGE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];

export const pants_006 = [
  {
    id: "HFD06P103",
    img: "/images/HFD__0012_HFD06P103_BEIGE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06P001_BLACK",
    img: "/images/HFD__0008_HFD06P001_BLACK_4.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06P022",
    img: "/images/HFD__0049_HFD06P022_LIGHT PURPLE_1 copy.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06P025",
    img: "/images/HFD__0050_HFD06P025_LIGHT YELLOW_1 copy.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06P101_BLACK",
    img: "/images/HFD__0010_HFD06P101_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06P021",
    img: "/images/HFD__HFD06P021_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06P023",
    img: "/images/HFD__0039_HFD06T023_LIGHT BLUE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06P102",
    img: "/images/HFD__0011_HFD06P102_BLACK_4.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];

export const shirts_006 = [
  {
    id: "HFD06B101",
    img: "/images/HFD__0003_HFD06B101_WHITE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06B001_BLACK",
    img: "/images/HFD__0002_HFD06B001_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06B102",
    img: "/images/HFD__0025_HFD06B102_ALL OVER_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];

export const tees_006 = [
  {
    id: "HFD06T104",
    img: "/images/HFD__0018_HFD06T104_WHITE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06T103",
    img: "/images/HFD__0017_HFD06T103_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06T005_LIGHT_YELLOW",
    img: "/images/HFD__0052_HFD06T005_LIGHT YELLOW_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
    ],
  },
  {
    id: "HFD06T107",
    img: "/images/HFD__0028_HFD06T107_ALL OVER_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06T201",
    img: "/images/HFD__0022_HFD06T201_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
    ],
  },
  {
    id: "HFD06T002",
    img: "/images/HFD__0040_HFD06T002_LIGHT BLUE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
      // },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06T108",
    img: "/images/HFD__0029_HFD06T108_ALL OVER_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06T108",
    img: "/images/HFD__0016_HFD06T101_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06T001",
    img: "/images/HFD__0013_HFD06T001_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06T003",
    img: "/images/HFD__0051_HFD06T003_GREY MELANGE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T004",
    img: "/images/HFD__0034_HFD06T004_LIGHT RED_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T006_PINK",
    img: "/images/HFD__0055_HFD06T006_PINK STRIPES_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T006_BLUE",
    img: "/images/HFD__0053_HFD06T006_BLUE STRIPES_2.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T005_WHITE",
    img: "/images/HFD__0014_HFD06T005_WHITE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T105",
    img: "/images/HFD__0019_HFD06T105_WHITE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T202",
    img: "/images/HFD__0042_HFD06T202_DARK PURPLE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T203",
    img: "/images/HFD__0030_HFD06T203_DARK RED_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T007",
    img: "/images/HFD__0015_HFD06T007_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];

export const otherShit_006 = [
  {
    id: "HFD06K102",
    img: "/images/HFD__0027_HFD06K102_ALL OVER_4.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06K001",
    img: "/images/HFD__0046_HFD06K001_PINK STRIPES_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06U001",
    img: "/images/HFD__0024_HFD06U001_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06K101_BEIGE",
    img: "/images/HFD__0006_HFD06K101_BEIGE_2.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // },
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06K008",
    img: "/images/HFD__HFD06K008_BLACK_4.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06K005",
    img: "/images/HFD__HFD06K005_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
];

export const hoodies_006 = [
  {
    id: "HFD06T224",
    img: "/images/HFD__0057_HFD06T224_GREY MELANGE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
    ],
  },
  {
    id: "HFD06T221",
    img: "/images/HFD__0023_HFD06T221_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06T122",
    img: "/images/HFD__0021_HFD06T122_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06T223",
    img: "/images/HFD__0031_HFD06T223_DARK RED_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T021",
    img: "/images/HFD__0009_HFD06T021_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06T022",
    img: "/images/HFD__0000_HFD06T022_LIGHT PURPLE_2.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06T025",
    img: "/images/HFD__0054_HFD06T025_LIGHT YELLOW_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      //   {
      //     store: "beijing",
      //     src: "https://beijing.doverstreetmarket.com/",
      //   },
    ],
  },
  {
    id: "HFD06T023",
    img: "/images/HFD__0041_HFD06T023_LIGHT BLUE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
    ],
  },
  {
    id: "HFD06T121",
    img: "/images/HFD__0020_HFD06T121_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
  {
    id: "HFD06T222",
    img: "/images/HFD__0043_HFD06T222_DARK PURPLE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
    ],
  },
];

export const hats_006 = [
  {
    id: "HFD06K003",
    img: "/images/HFD__0005_HFD06K003_KHAKI_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //   {
      //     store: "london",
      //     src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/",
      //   },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      //     {
      //       store: "paris",
      //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      //     },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD06K103",
    img: "/images/HFD__0007_HFD06K103_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      //     {
      //       store: "ginza",
      //       src: "https://ginza.doverstreetmarket.com/",
      //     },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];
