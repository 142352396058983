export const tees_005 = [
  {
    id: "HFD05T003",
    img: "/images/HFD05P103_WHITE_1_0025_HFD05T003_WHITE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-cunty-t-shirt-knitted-white-hfd05t003-ss22",
      },
    ],
  },
  {
    id: "HFD05T003_BLACK",
    img: "/images/HFD05P103_WHITE_1_0024_HFD05T003_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-cunty-t-shirt-knitted-black-hfd05t003-ss22",
      },
    ],
  },
  // {
  //   id: "HFD05T003_GREY",
  //   img: "/images/HFD05P103_WHITE_1_0005_HFD05T003_GREY MELANGE_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "paris",
  //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
  //     },
  //   ],
  // },
  {
    id: "HFD05T201",
    img: "/images/HFD05P103_WHITE_1_0021_HFD05T201_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-peter-paid-whistle-song-black-hfd05t201-ss22",
      },
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05T202",
    img: "/images/HFD05P103_WHITE_1_0041_HFD05T202_PINK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-peter-paid-dr-love-tshi-pink-hfd05t202-ss22",
      },
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05T203",
    img: "/images/HFD05P103_WHITE_1_0032_HFD05T203_BEIGE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-peter-paid-spin-spin-su-beige-hfd05t203-ss22",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  // {
  //   id: "HFD05T002",
  //   img: "/images/HFD05P103_WHITE_1_0020_HFD05T002_BLACK_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "paris",
  //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T001",
  //   img: "/images/HFD05P103_WHITE_1_0011_HFD05T001_BLACK RED_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "singapore",
  //       src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T001_WHITE",
  //   img: "/images/HFD05P103_WHITE_1_0008_HFD05T001_WHITE PINK_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "singapore",
  //       src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  {
    id: "HFD05T102",
    img: "/images/HFD05P103_WHITE_1_0017_HFD05T102_YELLOW_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-there-are-yellow-hfd05t102-ss22",
      },
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      {
        store: "singapore",
        src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-dijon-x-keith-haring-there-are-no-limits",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
    ],
  },
  {
    id: "HFD05T103",
    img: "/images/HFD05P103_WHITE_1_0028_HFD05T103_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-all-of-sud-black-hfd05t103-ss22",
      },
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      {
        store: "singapore",
        src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-dijon-x-keith-haring-all-of-sudden-t-shirt",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  // {
  //   id: "HFD05T106",
  //   img: "/images/HFD05P103_WHITE_1_0030_HFD05T106_WHITE_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "paris",
  //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
  //     },
  //     {
  //       store: "london",
  //       src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "singapore",
  //       src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "beijing",
  //       src: "https://beijing.doverstreetmarket.com/",
  //     },
  //     {
  //       store: "la",
  //       src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "new york",
  //       src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T104",
  //   img: "/images/HFD05P103_WHITE_1_0014_HFD05T104_ALLOVER_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "singapore",
  //       src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "beijing",
  //       src: "https://beijing.doverstreetmarket.com/",
  //     },
  //     {
  //       store: "la",
  //       src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "ginza",
  //       src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
];

export const sweats_005 = [
  {
    id: "HFD05N402",
    img: "/images/HFD05P103_WHITE_1_0013_HFD05N402_MULTICO_1.jpg",
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-textured-crewneck-sweat-multico-hfd05n402-ss22",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      {
        store: "new york",
        src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-textured-crewneck-sweat-multi-hfd05n402-ss22",
      },
    ],
  },
  {
    id: "HFD05N401",
    img: "/images/HFD05P103_WHITE_1_0015_HFD05N401_MULTICO_1.jpg",
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-crewneck-sweater-knitte-multico-hfd05n401-ss22",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      {
        store: "new york",
        src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-crewneck-sweater-knitte-multi-hfd05n401-ss22",
      },
    ],
  },
  {
    id: "HFD05N001",
    img: "/images/HFD05P103_WHITE_1_0036_HFD05N001_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-cunty-fitted-jumper-kni-black-hfd05n001-ss22",
      },
    ],
  },
  // {
  //   id: "HFD05N001_BLUE",
  //   img: "/images/HFD05P103_WHITE_1_0046_HFD05N001_BLUE_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "paris",
  //       src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T022",
  //   img: "/images/HFD05P103_WHITE_1_0002_HFD05T022_YELLOW TURQUOISE_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "ginza",
  //       src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T022_PINK",
  //   img: "/images/HFD05P103_WHITE_1_0012_HFD05T022_PINK RED_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "ginza",
  //       src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T023",
  //   img: "/images/HFD05P103_WHITE_1_0050_HFD05T023_RED_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "london",
  //       src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T023_BEIGE",
  //   img: "/images/HFD05P103_WHITE_1_0023_HFD05T023_BEIGE_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "london",
  //       src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05T123",
  //   img: "/images/HFD05P103_WHITE_1_0018_HFD05T123_GREEN_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "beijing",
  //       src: "https://beijing.doverstreetmarket.com/",
  //     },
  //     {
  //       store: "la",
  //       src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
];

export const hoodies_005 = [
  // {
  //   id: "HFD05T221",
  //   img: "/images/HFD05P103_WHITE_1_0027_HFD05T221_BLACK_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "london",
  //       src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "ginza",
  //       src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "singapore",
  //       src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "beijing",
  //       src: "https://beijing.doverstreetmarket.com/",
  //     },
  //     {
  //       store: "la",
  //       src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  {
    id: "HFD05T223",
    img: "/images/HFD05P103_WHITE_1_0031_HFD05T223_BEIGE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-peter-paid-spin-spin-su-beige-hfd05t223-ss22",
      },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05T22_PINK",
    img: "/images/HFD05P103_WHITE_1_0016_HFD05T222_PINK_1 2.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-peter-paid-dr-love-swea-pink-hfd05t222-ss22",
      },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];

export const shirts_005 = [
  {
    id: "HFD05B104",
    img: "/images/HFD05P103_WHITE_1_0040_HFD05B104_IVORY_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-face-print-ivory-hfd05b104-ss22",
      },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05B105",
    img: "/images/HFD05P103_WHITE_1_0047_HFD05B105_BLUE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-clown-prin-blue-hfd05b105-ss22",
      },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05B101",
    img: "/images/HFD05P103_WHITE_1_0038_HFD05B101_PRINT_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-overall-pr-print-hfd05b101-ss22",
      },
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05B106",
    img: "/images/HFD05P103_WHITE_1_0007_HFD05B106_BLACK WHITE_1.jpg",
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-fluid-satin-asymmetrica-blk-wht-hfd05b106-ss22",
      },
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      {
        store: "new york",
        src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-fluid-satin-asymmetrica-black-hfd05b106-ss22",
      },
    ],
  },
];

export const jackets_005 = [
  {
    id: "HFD05J101",
    img: "/images/HFD05P103_WHITE_1_0000_HFD05J101_DENIM MEDIUM BLUE_1 2.jpg",
    buyInStore: false,
    links: [
      {
        store: "end",
        src: "https://www.endclothing.com/gb/honey-fucking-dijon-x-keith-haring-denim-jacket-hfd05j101-bl.html",
      },
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05J001",
    img: "/images/HFD05P103_WHITE_1_0035_HFD05J001_GREEN_1.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05J001_PINK",
    img: "/images/HFD05P103_WHITE_1_0049_HFD05J001_PINK_1.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05J002",
    img: "/images/HFD05P103_WHITE_1_0003_HFD05J002_NAVY YELLOW RED_1.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
    ],
  },
  {
    id: "HFD05C101",
    img: "/images/HFD05P103_WHITE_1_0039_HFD05C101_BLACK_1.jpg",
    buyInStore: false,
    latest: true,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-printed-po-black-hfd05c101-ss22",
      },
    ],
  },
];

export const pants_005 = [
  {
    id: "HFD05P002",
    img: "/images/HFD05P103_WHITE_1_0033_HFD05P002_GREEN_1.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05P002_PINK",
    img: "/images/HFD05P103_WHITE_1_0044_HFD05P002_PINK_1.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05P001_BLACK",
    img: "/images/HFD05P103_WHITE_1_0026_HFD05P001_BLACK_1.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05P001_BLUE",
    img: "/images/HFD05P001_BLUE_4.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05P101",
    img: "/images/HFD05P103_WHITE_1_0001_HFD05P101_DENIM MEDIUM BLUE_1.jpg",
    buyInStore: false,
    links: [
      {
        store: "end",
        src: "https://www.endclothing.com/gb/honey-fucking-dijon-x-keith-haring-jean-hfd05p101-bl.html",
      },
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      // {
      //   store: "singapore",
      //   src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05P103",
    img: "/images/HFD05P103_WHITE_1_0051_HFD05P103_WHITE_1.jpg",
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-fluid-satin-pants-woven-white-hfd05p103-ss22",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      {
        store: "new york",
        src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-fluid-satin-pants-woven-white-hfd05p103-ss22",
      },
    ],
  },
  // {
  //   id: "HFD05P102",
  //   img: "/images/HFD05P103_WHITE_1_0029_HFD05P102_PRINT_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "london",
  //       src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //     {
  //       store: "ginza",
  //       src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
  //     },
  //   ],
  // },
  // {
  //   id: "HFD05P124",
  //   img: "/images/HFD05P103_WHITE_1_0022_HFD05P124_GREEN_1.jpg",
  //   buyInStore: false,
  //   links: [
  //     {
  //       store: "beijing",
  //       src: "https://beijing.doverstreetmarket.com/",
  //     },
  //   ],
  // },
];

export const hats_005 = [
  {
    id: "HFD05K105_BLACK",
    img: "/images/HFD05K105_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-bassline-p-black-hfd05k105-ss22",
      },
      {
        store: "singapore",
        src: "https://shop-sg.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-dijon-x-keith-haring-bassline-print-bu",
      },
    ],
  },
  {
    id: "HFD05K003",
    img: "/images/HFD05P103_WHITE_1_0034_HFD05K003_BLACK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-honey-fucking-dijon-cap-black-hfd05k003-ss22",
      },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05K003_PINK",
    img: "/images/HFD05P103_WHITE_1_0048_HFD05K003_PINK_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-honey-fucking-dijon-cap-pink-hfd05k003-ss22",
      },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05K003_BLUE",
    img: "/images/HFD05P103_WHITE_1_0045_HFD05K003_BLUE_1.jpg",
    latest: true,
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-honey-fucking-dijon-cap-blue-hfd05k003-ss22",
      },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05K001_BLUE_2",
    img: "/images/HFD05K001_BLUE_2.jpg",
    buyInStore: false,
    links: [
      // {
      //   store: "london",
      //   src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-honey-fucking-dijon-cap-blue-hfd05k003-ss22",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
];

export const otherShit_005 = [
  {
    id: "HFD05S101",
    img: "/images/HFD05P103_WHITE_1_0010_HFD05S101_BLACK WHITE_1.jpg",
    buyInStore: false,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-drapy-satin-asymmetrica-blk-wht-hfd05s101-ss22",
      },
      // {
      //   store: "ginza",
      //   src: "https://shop-jp.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      {
        store: "new york",
        src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-drapy-satin-asymmetrica-black-hfd05s101-ss22",
      },
    ],
  },
  {
    id: "HFD05K107",
    img: "/images/HFD05P103_WHITE_1_0004_HFD05K107_MILITARY GREEN_1.jpg",
    buyInStore: false,
    latest: true,
    links: [
      // {
      //   store: "paris",
      //   src: "https://www.doverstreetlittlemarket.com/collections/honey-fucking-dijon",
      // },
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-printed-ba-militar-hfd05k107-ss22",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
      // {
      //   store: "la",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
      // {
      //   store: "new york",
      //   src: "https://shop-us.doverstreetmarket.com/collections/shops-honey-fucking-dijon",
      // },
    ],
  },
  {
    id: "HFD05K108",
    img: "/images/HFD05P103_WHITE_1_0006_HFD05K108_GREEN PURPLE_1.jpg",
    buyInStore: false,
    latest: true,
    links: [
      {
        store: "london",
        src: "https://shop.doverstreetmarket.com/collections/shops-honey-fucking-dijon/products/honey-fucking-dijon-unisex-keith-haring-withches-s-green-p-hfd05k108-ss22",
      },
      // {
      //   store: "beijing",
      //   src: "https://beijing.doverstreetmarket.com/",
      // },
    ],
  },
];
